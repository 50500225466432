import {useEffect, useState} from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles';


import { Navbar, Footer } from './components/index'
import { Welcomepage, Menu, Lunch, Event, Contact, Icecream, Booking, AbortBookingPage, Gallery2 } from './containers/index'
import { ForgotPasswordPage } from './dashboard/index'

import { Dashboard } from './dashboard/index'


//redux
import { AppDispatch } from './store'
import { useDispatch } from 'react-redux'
import { GetInfoChangeAble, GetInfo } from './slices/tokenReducer';


const App = () => {
  
  const [information, setInformation] = useState<any>()
  const [information2, setInformation2] = useState<any>()

  const dispatch: AppDispatch = useDispatch()
  // FETCH info.json
  useEffect(() => {
    async function func() {
        await dispatch(GetInfo({}))
        .unwrap()
        .then(data => {            
           
            setInformation(data)                       
        })  
        await dispatch(GetInfoChangeAble({}))
        .unwrap()
        .then(data => {            
            setInformation2(data)                       
        })      
    }
    func()
    
   
  }, [])
 
  //* Gsap
/*   useLayoutEffect(() => {
		const ctx = gsap.context(()=>{   								
      gsap.registerPlugin(ScrollTrigger); */

   /*  const section1Animation = gsap.timeline();
    section1Animation.from(section1Ref.current, {
      yPercent: -100,
      duration: 1
    });

    const section2Animation = gsap.timeline();
    section2Animation.from(section2Ref.current, {
      yPercent: 100,
      duration: 1
    }); */

   /*  const scrollAnimation = gsap.timeline();

    scrollAnimation.to(section2Ref.current, {
      yPercent: -100,
ease: 'power2.out',
      scrollTrigger: {
        trigger: section1Ref.current,
        start: 'bottom bottom',
        end: '+=100',
        scrub: true,
        markers:true,

      }
    }); */

   /*  ScrollTrigger.create({
      trigger: section2Ref.current,
      start: 'top center',
      end: 'bottom top',
      scrub: true,
      animation: section2Animation
    }); */

        
   /*  },app_ref)  
    return () => {
      ctx.revert()
    };
  }, []) */

  const defaultTheme = createTheme()

  return (
  
      <Router>
        <ThemeProvider theme={defaultTheme}>
        <Navbar />      
          <Routes>          
            <Route path="" element={<Welcomepage />            
            }/>    
            <Route path='/menu' element={<Menu
              Image_1={information?.Menu?.Image_1} 
              Image_2={information?.Menu?.Image_2} 
              Image_3={information?.Menu?.Image_3} 
              Image_4={information?.Menu?.Image_4} 
              Menu_Category_1={information?.Menu?.Menu_Category_1}
              Menu_Category_2={information?.Menu?.Menu_Category_2}
              Menu_Category_3={information?.Menu?.Menu_Category_3}
              Menu_Category_4={information?.Menu?.Menu_Category_4}
             />}/>
            <Route path='/lunch' element={<Lunch             
              Title_1={information?.Lunch?.Title_1} 
              SubTitle_1={information?.Lunch?.SubTitle_1}               
              Lunch_Info={information2?.Information?.Lunch_Info}               
              Error_1={information?.Lunch?.Error_1}
              Image_1={information?.Lunch?.Image_1}                            
             />}/>
            <Route path='/event' element={<Event             
              Title_1={information?.Event?.Title_1}
              IntroText_1={information?.Event?.IntroText_1}
              Image_1={information?.Event?.Image_1}
              Navgiate_Button_1={information?.Event?.Navgiate_Button_1}              
             />}/>
            <Route path='/contact' element={<Contact             
              Title_1={information?.Contact?.Title_1} 
              Info_1={information?.Contact?.Info_1} 
              Checkbox_1={information?.Contact?.Checkbox_1} 
              Link_Text={information?.Contact?.Link_Text} 
              Link={information?.Contact?.Link}              
             />}/>
             <Route path='/icecream' element={<Icecream             
                Title_1={information?.Icecream?.Title_1}
                IntroText_1={information?.Icecream?.IntroText_1}
             />}/>
             <Route path='/gallery' element={<Gallery2             
                Title_1={information?.Gallery2?.Title_1}
                IntroText_1={information?.Gallery2?.IntroText_1}
             />}/>
             <Route path='/booking' element={<Booking             
                Checkbox_1={information?.Booking?.Checkbox_1}
                Prev_Button={information?.Booking?.Prev_Button}
                Lunch_Button={information?.Booking?.Lunch_Button}
                Middag_Button={information?.Booking?.Middag_Button}
                Form_Type={information?.Booking?.Form_Type}
                Form_Guests={information?.Booking?.Form_Guests}
                Form_Date={information?.Booking?.Form_Date}                
                Booking_Button={information?.Booking?.Booking_Button}
                Success_Button={information?.Booking?.Success_Button}
                Error_Button={information?.Booking?.Error_Button}
             />}/>
             <Route path='/abortbookingpage/:id' element={<AbortBookingPage             
              Title_1={information?.AbortBookingPage?.Title_1}
              Abort_Button={information?.AbortBookingPage?.Abort_Button}
              Home_Button={information?.AbortBookingPage?.Home_Button}
             />}/>
              <Route path='/forgotpassword/:id/:userid' element={<ForgotPasswordPage />}/>
			 
             <Route path="/dashboard/*" element={
                <Dashboard
                  
                />}
              />
          </Routes>
       
        <Footer 
          Title_1={information?.Footer?.Title_1} 
          Title_2={information?.Footer?.Title_2} 
          Title_3={information?.Footer?.Title_3} 
          Title_4={information?.Footer?.Title_4} 
          Link_Text={information?.Footer?.Link_Text} 
          Company_Name={information?.Footer?.Company_Name} 

          Email={information2?.Information?.Email} 
          Phone_1={information2?.Information?.Phone_1} 
          Phone_2={information2?.Information?.Phone_2} 
          Address={information2?.Information?.Address} 
          
          

          Times={information2?.Information?.Times} 
          Link_Instagram={information2?.Information?.Link_Instagram} 
          Link_Facebook={information2?.Information?.Link_Facebook} 
          Link_Map={information2?.Information?.Link_Map} 

          Image={information?.Footer?.Image}
        />

        </ThemeProvider>
      </Router>
   
  )
}

export default App