import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

// REDUX
import { PostBooking, GetBookingTimes } from '../../slices/bookingReducer'; 
import { FetchInfoFromStore } from '../../functions/Info'
import { FetchReservedDates } from '../../functions/Booking';
import  store  from '../../store'

// React icons
import { IoIosArrowBack } from 'react-icons/io'

// Self imports
import './Booking.css'

interface bookinginit {[key:string]:string}
const Booking = ({Checkbox_1,Prev_Button,Lunch_Button,Middag_Button,Form_Type, Form_Guests, Form_Date, Booking_Button,Success_Button, Error_Button}:bookinginit) => {
    type AppDispatch = typeof store.dispatch
    const useAppDispatch = () => useDispatch<AppDispatch>()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    interface forminit {
        type: string
        people: number
        date: number
        time: number
        dateNow: number
        firstName: string
        lastName: string
        email: string
        phone: string
        other: string
        approved_storage: boolean
    }
    const [form, setForm] = useState<forminit>({
        type: "",
        people: 0,
        date: 0,
        time: 0,
        dateNow: new Date().setHours(0,0,0,0),
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        other: "",
        approved_storage: false,
    })
    const [information, setInformation] = useState<any>({})
    const [freeTimes, setFreeTimes] = useState<number []>([])
    const [message, setMessage] = useState<string>("")
    const [page, setPage] = useState<number>(0)   

    const [disabledDates, setDisabledDates] = useState<number []>([])
   
    const [loading, setLoading] = useState(false);
    // useEffect    
    // GetInfo from db and sets information state
    useEffect(() => {
        const asyncfunc = async() => {
            const res:any = await FetchInfoFromStore()
      
            setInformation(res)
            
            let tempArr = [...disabledDates] 
            let concatArr = tempArr.concat(res?.Booking_turnOffDatesOnCalender)
          
            setDisabledDates([...concatArr])
        }
        asyncfunc()        
    }, [])
    
   

    //* Functions Disptach
    // When date is pushed, get the free times at that date
    const GetFreeBookingTimes = async(date:number) => {
        setLoading(true)
        await dispatch(GetBookingTimes({date: date, people:form.people, type:form.type}))
            .then(unwrapResult)
            .then((res:any) => {                
                
                if(res?.statusCode === 200){
                    setFreeTimes(res?.body?.data)
                    NextPage()
                }else{
                    setMessage(res?.body?.message || "Ett fel uppstod, ladda om sidan och försök igen")
                }      
                setLoading(false)
                
        })
    }
    // When People is pushed, get the dates that are Not Free
    const GetOccupiedBookingDates = async(people:number) => {                
        setLoading(true)
        const asyncfunc = async() => {
            const res:any = await FetchReservedDates(people, form.type, form.dateNow)                    
            const tempArr = [...disabledDates]
            let concatArr = tempArr.concat(res)            
            setDisabledDates([...concatArr])
            setLoading(false)            
            NextPage()
        }
        asyncfunc() 
    }
   
    const Submit = async(e:any) => {
        e.preventDefault()
        setMessage("")
        if(form.firstName === ""){
            setMessage("Ni måste fylla i korrekt förnamn")
            return
        }else if(form.lastName === ""){
            setMessage("Ni måste fylla i korrekt efternamn")
            return
        }else if(form.email === ""){
            setMessage("Ni måste fylla i en korrekt email")
            return
        }else if(!form.approved_storage){
            setMessage("Ni måste acceptera lagring")
            return
        }
        setLoading(true)
        await dispatch(PostBooking(
            {
                people: form.people,
                type:form.type,
                dateNow:form.dateNow,
                date: form.date,
                time: form.time,
                email: form.email,
                firstName: form.firstName,
                lastName: form.lastName,
                approved_storage: form.approved_storage,
                other: form.other,
                phone: form.phone,

            }))
            .then(unwrapResult)
            .then((res:any) => {                
                
                if(res?.statusCode === 200){
                    setPage(5)
                }else{
                    setPage(6)
                }            
                setMessage(res?.body?.message)
                setLoading(false)
            })
    }


    //* Functions
    
    // Enters booking data in to form
    const HandleBooking = (name:string,value:string|number) => {
        setForm(prev => ({...prev, [name]:value}))
        
    }    
    // When pressing the back arrow, navigates one step backword, resets values infront
    const HandleGoback = () => {   
        switch (page) {
            case 1:
                setForm(prev=>({...prev,                    
                    people: 0,
                    type:""
                }))
                PrevPage()   
                break;
            case 2:
                setForm(prev=>({...prev,                    
                    date: 0,
                    people:0
                }))
                PrevPage()   
                break;
            case 3:
                setForm(prev=>({...prev,                    
                    time: 0,
                    date: 0,
                }))
                PrevPage()   
                break;
            case 4:
                setForm(prev=>({...prev,                    
                    time: 0,                    
                }))
                PrevPage()   
                break;      
            default:
                break;
        }        
    }
    // When pressing the different categories, navigates only backwords, resets values infront
    const HandleGobackNav = (category: number) => {
        switch (category) {
            case 0:
                setForm(prev=>({...prev,
                    people: 0,
                    date: 0,
                    time: 0,
                }))
                setPage(prev => category) 
                break;
            case 1:
                setForm(prev=>({...prev,
                    date: 0,
                    time: 0,
                }))
                setPage(prev => category) 
                break;
            case 2:
                setForm(prev=>({...prev,
                    time: 0,
                }))
                setPage(prev => category) 
                break;
            default:
                break;
        }                
    }
    // Changes page forward
    const NextPage = () => {       
        setPage(prev => page + 1)
    }
    // Changes page backward
    const PrevPage = () => {
        setPage(prev => page - 1)   
    }
    // Checks all disabledDateds
    const CheckIfDisabled = (date:number) => {
        let onlyEpochArray = disabledDates.map((obj:any) => obj?.date)
        return onlyEpochArray?.includes(new Date(date).getTime() )
    }




    //* Components
    // Selects which page to display
    const SectionViewer = () => {
        const Viewer = () => {
            switch(page) {
                case 0:
                    return TypeView()                       
                case 1:
                    return PeopleView()                       
                case 2:
                    return DateView()                       
                case 3:
                    return TimeView()                       
                case 4:
                    return FormView()                
                case 5:
                    return SuccessView()                       
                case 6:
                    return ErrorView()          
                default:
                    break;
            }
        }
        
        return (
        <div className='booking flex__center-c'>
            <p className='p__font-xsmall'> {message}</p>
            { (page !== 6 && page !== 5 && page !== 0) && <span className='p__font-small flex' onClick={HandleGoback}><IoIosArrowBack id='react-icon-back'/>{Prev_Button}</span>}
            <div className='booking-navbar'>
                
            { (page !== 6 && page !== 5) &&    <div className='booking-navbar-upper flex'>
                    <p className='p__font-xsmall' onClick={()=>HandleGobackNav(0)}>{form?.type}</p>                    
                    <p className='p__font-xsmall' onClick={()=>HandleGobackNav(1)}>{form?.people !== 0 && `${form?.people} Gäster`}</p>
                    <p className='p__font-xsmall' onClick={()=>HandleGobackNav(2)}>{form?.date !== 0 && new Date(form?.date).toLocaleDateString("sv-SE", {timeZone: 'Europe/Stockholm' })}</p>
                    <p className='p__font-xsmall' onClick={()=>HandleGobackNav(3)}>{form?.time !== 0 && new Date(form?.time).toLocaleTimeString("sv-SE", { hour: "2-digit", minute: "2-digit"})}</p>
                   
                </div> 
            }
               
            </div>            
            {Viewer()}                      
        </div>
        )
    }
    const TypeView = () => {
        return(
            <div>
                <h2 className='h2__font-small' style={{textAlign:"center"}}>Middag eller lunch?</h2>
                <div className='booking__type flex__center-r'>
                    <button className='btn-oval p__font-small' onClick={()=>{HandleBooking("type","Lunch"); NextPage()}} >{Lunch_Button}</button>

                    <button className='btn-oval p__font-small' onClick={()=>{HandleBooking("type","Middag"); NextPage()}}>{Middag_Button}</button>
                </div>
            </div>
        )
    }
    const PeopleView = () => {
        return (
            <div>
                <h2 className='h2__font-small' style={{textAlign:"center"}}>Antal gäster</h2>
                <div className='booking__people flex__center-r'>
                    {Array.from({length:information?.maxNumberOfGuestPerReservation || 16 } ,(_,i) => (                 
                        <div className='flex__center-c p__font-small' key={`people${i}`} onClick={async()=>{HandleBooking("people",i+1); await GetOccupiedBookingDates(i+1);}}>
                            {i+1}
                        </div>                 
                    ))}
                </div>
            </div>
        )
    }
    const DateView = () => {        
        return (
            <div>
                <h2 className='h2__font-small' style={{textAlign:"center"}}>Datum</h2>
            
                <div className='booking__date flex'>
                <Calendar  
                    minDate={new Date()}
                    maxDate={new Date(Date.now()+3600000*24*365)}
                    onClickDay={async(value, event)=>{HandleBooking("date",new Date(value).getTime()); await GetFreeBookingTimes(new Date(value).getTime()) }}  /* when a day is clicked save info */
                    tileDisabled={({activeStartDate, date, view }:any) => CheckIfDisabled(date)  } /* For disabling some days */
                />
                </div>
            </div>
        )
    }
    const TimeView = () => {
       
        return (
            <div>
                <h2 className='h2__font-small' style={{textAlign:"center"}}>Vilken Tid?</h2>
                
                <div className='booking__time flex__center-r'>
                    {freeTimes?.map((time,i)=>{
                        return (
                            <div className='flex__center-c' key={`times${i}`} onClick={()=>{HandleBooking("time",time);NextPage()} }>
                                <p className='p__font-xsmall'>{new Date(time).toLocaleTimeString("sv-SE", { hour: "2-digit", minute: "2-digit" })}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
    const FormView = () => {
        return (
            <div className='booking__form'>
                <div className='booking__form-info flex-c'>
                    <p className='p__font-small'>{Form_Type} {form?.type}</p>
                    <p className='p__font-small'>{Form_Guests} {form?.people}</p>
                    <p className='p__font-small'>{Form_Date}  {new Date(form?.date).toLocaleDateString("sv-SE", {timeZone: 'Europe/Stockholm' })} kl. {new Date(form?.time).toLocaleTimeString("sv-SE", { hour: "2-digit", minute: "2-digit" })}</p>
                    <p className='p__font-small'></p>                    
                </div>
                {/* Form */}
                 <form className='contact__form'>

                    {/* First & Lastname */}
                    <div className='contact__form-name flex'>
                        <input type="text" onChange={(e)=>HandleBooking(e.target.name,e.target.value)} value={form?.firstName} placeholder="Förnamn*" name='firstName' required></input>
                        <input type="text" onChange={(e)=>HandleBooking(e.target.name,e.target.value)} value={form?.lastName} placeholder="Efternamn*" name='lastName' required></input>
                    </div>

                    {/* Email */}
                    <div className='contact__form-email'>
                        <input type="text" onChange={(e)=>HandleBooking(e.target.name,e.target.value)} value={form?.email} placeholder="Email*" name='email' required></input>  
                    </div>

                    {/* Phone */}
                    <div className='contact__form-phone flex'>            
                        <input type="tel" onChange={(e)=>HandleBooking(e.target.name,e.target.value)} value={form?.phone} placeholder="Telefonnummer" name='phone'></input>  
                    </div>  


                    {/* Message */}
                    <div className='contact__form-msg flex'>
                        <textarea onChange={(e)=>HandleBooking(e.target.name,e.target.value)} value={form?.other} placeholder="Meddelande" name='other'></textarea>                          
                    </div>

                    {/* Subscription checkbox */}
                    <div className='contact__form-subscription flex__center-r'>
                        <p className='p__font-xsmall'>{Checkbox_1}</p>
                        <div className="checkbox-wrapper-19">
                            <input 
                            type="checkbox"   
                            id="cbtest-19" 
                            onChange={(e)=>setForm(prev => ({...prev,
                                [e.target.name]: !form?.approved_storage
                            }))} 
                            name="approved_storage" 
                            value={form?.approved_storage as any}
                            
                            />
                            <label htmlFor="cbtest-19" className="check-box" />
                        </div>
                    </div>


                    {/* Submit Button */}
                    <div className='contact__form-submit navbar__navigation-sqrbtn flex__center-c '>             
                        <button onClick={(e)=>Submit(e)} className='square-btn'>{Booking_Button}</button>         
                    </div>


                    {/* Error message */}
                    {message &&
                    <div className='flex__center-r'>
                    <p className='p__font-small'>{message}</p>
                    </div>
                    }

                    

                </form>   

               

            </div>
        )
    }
    const SuccessView = () => {
        return(
        <div className='booking__success flex__center-c'>
      
            <span className='p__font-small flex' onClick={()=>navigate("/")}><IoIosArrowBack id='react-icon-back'/>{Prev_Button}</span>
        </div>
        )
    }
    const ErrorView = () => {
        return(
        <div className='booking__success flex__center-c'>
         
            <span className='p__font-small flex' onClick={()=>navigate("/")}><IoIosArrowBack id='react-icon-back'/>{Prev_Button}</span>
        </div>
        )
    }
 
    return (
        <section className='container__booking flex__center-c'>
            {SectionViewer()}       
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}                    
            >
                <CircularProgress color="inherit" />
            </Backdrop>    
        </section>
    )
}

export default Booking
